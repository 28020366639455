<template>
  <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
    <div class="space-y-1 pb-8">
      <router-link
        v-for="item in navigation"
        :key="item.id"
        :to="item.href"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <component
          :is="item.icon"
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span class="truncate"> {{ item.name }} </span>
      </router-link>
    </div>
    <div class="mt-6 pt-6">
      <div class="space-y-1 px-2">
        <a
          v-for="item in secondaryNavigation"
          :key="item.name"
          :href="item.href"
          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        >
          <component
            :is="item.icon"
            class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <span class="truncate"> {{ item.name }} </span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  CogIcon,
  UserGroupIcon,
  CreditCardIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  OfficeBuildingIcon,
  DocumentReportIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/outline";

const navigation = [
  {
    id: 0,
    name: "Users",
    href: "/admin/users",
    icon: UserGroupIcon,
    current: true,
  },
  {
    id: 1,
    name: "Roles",
    href: "/admin/roles",
    icon: AcademicCapIcon,
    current: false,
  },
  {
    id: 2,
    name: "Organizations",
    href: "/admin/organisations",
    icon: OfficeBuildingIcon,
    current: false,
  },
  {
    id: 3,
    name: "Subscriptions",
    href: "/admin/subscriptions",
    icon: CreditCardIcon,
    current: false,
  },
  {
    id: 4,
    name: "Managment",
    href: "/admin/management",
    icon: CogIcon,
    current: false,
  },
  {
    id: 5,
    name: "Reports",
    href: "/admin/reports",
    icon: DocumentReportIcon,
    current: false,
  },
];

const secondaryNavigation = [
  { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "/privacy", icon: ShieldCheckIcon },
];

export default {
  components: {
    CogIcon,
    UserGroupIcon,
    CreditCardIcon,
    AcademicCapIcon,
    ShieldCheckIcon,
    OfficeBuildingIcon,
    DocumentReportIcon,
    QuestionMarkCircleIcon,
  },
  setup() {
    return {
      navigation,
      secondaryNavigation,
    };
  },
};
</script>

<style lang="scss" scoped></style>
